import career from '../assets/career.png';
import mcma from '../assets/mcma.png';
import mlvk from '../assets/mlvk.png';
import mly from '../assets/mly.png';
import mqa from '../assets/mqa.png';
import '../App.css';
import React from 'react';

const CareerSection = () => {

    return (
        <div id="career" className="section career-section">
        <div className="section-lines-container">
          <div className="line"></div>
          <img src={career} alt="Career" className="section-icon" />
          <div className="line"></div>
        </div>
        <div className='career-card-container'>
            <h3>职业生涯</h3>
            <h2>毕业证含金量, 4大机构认证</h2>
            <p>—— 拥有绝对的就业及升学优势！</p>
        </div>
        <div className='career-cards-container'>
          <div className='career-cards'>
            <img src={mcma} alt='MCMA' className='career-cards-logo-mcma'/>  
            <div className='career-cards-text'>
              <p>马来西亚中医总会</p>
              <p>Malaysia Chinese Medical Association</p>
            </div>
          </div>
          <div className='career-cards'>
            <img src={mlvk} alt='MLVK' className='career-cards-logo-mlvk'/>  
            <div className='career-cards-text'>
              <p>大马技术学术局</p>
              <p>National Vocational Training Council</p>
            </div>
          </div>
        </div>
        <div className='career-cards-container'>
          <div className='career-cards'>
            <img src={mly} alt='MLY' className='career-cards-logo-mly'/>  
            <div className='career-cards-text'>
              <p>马来西亚政府</p>
              <p>Malaysia Government</p>
            </div>
          </div>
          <div className='career-cards'>
            <img src={mqa} alt='MQA' className='career-cards-logo-mqa'/>  
            <div className='career-cards-text'>
              <p>马来西亚学术资格鉴定机构</p>
              <p>Malaysian Qualifications Agency</p>
            </div>
          </div>
        </div>
      </div>
    );
};

export default CareerSection;