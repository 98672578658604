import modern from '../assets/modernization.png';
import modernImg from '../assets/img.png';
import modernImg1 from '../assets/img2.png';
import collaborate from '../assets/high-five.png';
import '../App.css';
import React from 'react';

const ModernSection = () => {

    return (
        <div id="modern" className="section modern-section">
        <div className="section-lines-container">
          <div className="line"></div>
          <img src={modern} alt="Modern" className="section-icon" />
          <div className="line"></div>
        </div>
        <div className='modern-card-container'>
          <div className='modern-card'>
            <div className='modern-card-left'>
              <img src={modernImg} alt='Modern' className='first-card'></img>
              <img src={modernImg1} alt='Modern' className='second-card'></img>
            </div>
            <div className='modern-card-right'>
              <h3>总则</h3>
              <p>New Axis Training Centre教育不仅旨在开发学生的学术和智力潜力。道德和品格建设目标是课程中非常重要的一部分。事实上，教育的主要关注点是将人格视为一个完整的整体，包括身体、情感、智力、精神和社会方面。</p>
              <p>为了履行其使命和目标，学校通过实施精心设计的课程，努力确保教学质量，并将持续监控和严格评估所有相关活动和系统，以确保教学水平。</p>
              <div className='modern-right-container'>
                <h3>着重于学生的</h3>
                <div className='modern-cards-container'>
                  <h2>职业与就业规划</h2>
                </div>
              </div>
            </div>
          </div>
          <div className='modern-bottom-card'>
            <div className='modern-bottom-icon'>
              <img src={collaborate} alt='Collaborate' className='modern-icon'></img>
            </div>
            <div className='modern-bottom-text'>
              <p>鼓励学生积极参与学习过程，并通过实践、协作和探索来积累知识和技能。</p>
            </div>
          </div>
        </div>
      </div>
    );
};

export default ModernSection;