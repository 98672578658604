import React, { useEffect, useState } from 'react';
import './TopBar.css';
import logo from '../assets/logo.png'; 

const TopBar = () => {

    const [activeSection, setActiveSection] = useState('');
    const [isMenuOpen, setIsMenuOpen] = useState(false);


    const handleScroll = () => {
      const sections = ['home', 'about', 'modern', 'career', 'contact'];
      let currentSection = '';
      
      sections.forEach(section => {
        const sectionElement = document.getElementById(section);
        const rect = sectionElement.getBoundingClientRect();
        if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {
          currentSection = section;
        }
      });
  
      setActiveSection(currentSection);
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
      };


    const handleMenuClick = (sectionId) => {
      setIsMenuOpen(false); 
      setActiveSection(sectionId); 
    };
    
  
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className="top-bar">
          <img src={logo} alt="Logo" className="logo" />
          <div className='menu'>
          <button className="hamburger" onClick={toggleMenu}>☰</button>
            <nav className={`nav-menu ${isMenuOpen ? 'open' : ''}`}>
                <ul>
                  <li><a href="#home" className={activeSection === 'home' ? 'active' : ''} onClick={() => handleMenuClick('home')}>首页</a></li>
                  <li><a href="#about" className={activeSection === 'about' ? 'active' : ''} onClick={() => handleMenuClick('about')}>关于</a></li>
                  <li><a href="#modern" className={activeSection === 'modern' ? 'active' : ''} onClick={() => handleMenuClick('modern')}>总则</a></li>
                  <li><a href="#career" className={activeSection === 'career' ? 'active' : ''} onClick={() => handleMenuClick('career')}>职业生涯</a></li>
                  <li><a href="#contact" className={activeSection === 'contact' ? 'active' : ''} onClick={() => handleMenuClick('contact')}>联络</a></li>
                </ul>
            </nav>
          </div>
        </div>
      );
    };
    

export default TopBar;
