import TopBar from './components/TopBar'; 
import './App.css'; 
import HomeSection from './components/Home';
import AboutSection from './components/About';
import ModernSection from './components/Modern';
import CareerSection from './components/Career';
import ContactSection from './components/Contact';

function App() {

  return (
    <div className="App">
      <TopBar />
      <HomeSection />
      <AboutSection />
      <ModernSection />
      <CareerSection />
      <ContactSection />
      </div>
  );
}

export default App;
