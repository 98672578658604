import '../App.css';
import React from 'react';
import contact from '../assets/contact-mail.png';
import logo from '../assets/logo.png';
import insta from '../assets/instagram.png';
import fb from '../assets/facebook.png';
import location from '../assets/location.png';
import phone from '../assets/phone-call.png';
import mail from '../assets/mail.png';
import whatsapp from  '../assets/whatsapp.png';

const ContactSection = () => {
    
    return (
        <div id="contact" className="section contact-section">
            <div className="section-lines-container">
                <div className="line"></div>
                <img src={contact} alt="Contact" className="section-icon" />
                <div className="line"></div>
            </div>
            <div className='contact-card-container'>
                <img src={logo} alt="Logo" className="contact-logo" />
                <div className='contact-cards-container'>
                    <div className='contact-cards-1'>
                        <h4>连接我们</h4>
                        <h5>New Axis Training Centre</h5>
                        <div className='contact-media-logo'>
                            <img src={insta} alt="Instagram" className='instagram' />
                            <img src={fb} alt="Facebook" className='facebook' />
                        </div>
                    </div>
                    <div className='contact-cards-2'>
                        <h4>找到我们</h4>
                        <div className='contact-cards-content'>
                            <img src={location} alt='Location' className='contact-icon' />
                            <h5 className='contact-text'>New Axis Training Centre</h5>
                        </div>
                        <h5 className='contact-text'> 30 Jalan Kelumpang, Pelabuhan Klang, Selangor.</h5>
                    </div>
                    <div className='contact-cards-3'>
                        <h4>感兴趣?</h4>
                        <div className='contact-cards-content'>
                            <img src={phone} alt='Phone' className='contact-icon' />
                            <h5 className='contact-text'>直接拨打 +60106502745</h5>
                        </div>
                        <div className='contact-cards-content'>
                            <img src={mail} alt='Mail' className='contact-icon' />
                            <h5 className='contact-text'>发送邮件至 newaxistrainingcentre@gmail.com</h5>
                        </div>
                        <div className='contact-cards-content'>
                            <img src={whatsapp} alt='Whatsapp' className='contact-icon' />
                            <h5 className='contact-text'>私信 Whatsapp +60106502745</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactSection;