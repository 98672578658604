import about from '../assets/info.png';
import aim from '../assets/aim.png';
import idea from '../assets/idea.png';
import training from '../assets/training.png';
import su from '../assets/su.png';
import jin from '../assets/jin.png';
import hui from '../assets/hui.png';
import yang from '../assets/yang.png';
import zhou from '../assets/zhou.png';
import feng from '../assets/feng.png';
import '../App.css';
import React from 'react';

const AboutSection = () => {

    return (
        <div id="about" className="section about-section">
        <div className="section-lines-container">
          <div className="line"></div>
          <img src={about} alt="About" className="section-icon" />
          <div className="line"></div>
        </div>
        <div className="cards-container">
          <div className="card">
            <img src={aim} alt="Aim" className="about-sub-icon" />
            <h4>校训</h4>
            <p>勤求博采</p>
            <p>厚德济世</p>
          </div>
          <div className="card">
          <img src={idea} alt="Idea" className="about-sub-icon" />
            <h4>办学理念</h4>
            <p>勤学苦练</p>
            <p>传承创新</p>
          </div>
          <div className="card">
          <img src={training} alt="Training" className="about-sub-icon" />
            <h4>宗旨</h4>
            <p>医者仁心</p>
            <p>全人健康</p>
          </div>
        </div>
        <div className='starter-card-container'>
          <div className='starter-left-card'>
            <div className='starter-left-text'>
              <h2>New Axis Training Centre</h2>
              <h1>团队建设</h1>
            </div>
            <div className='starter-right-line'>
              <div className='vertical-line'></div>
            </div>
          </div>
          <div className='starter-right-card'>
            <div className='starter-right-cards'>
              <div className='subtitle'>
                <img src={su} alt="SuYouRen" className="about-subtitle-icon" /> 
                <p>首席执行官-苏幼仁</p>
              </div>
              <div className='subtitle1'>
                <p>马来西亚城市大学本科学院院长 博士</p>
              </div>
            </div>
            <div className='starter-right-cards'>
              <div className='subtitle'>
                <img src={jin} alt="JinMeiHua" className="about-subtitle-icon" /> 
                <p>校长-金美花</p>
              </div>
              <div className='subtitle1'>
                <p>毕业于北京中医药大学 硕士</p>
                <p>先后在西苑医院及北京中医药大学从事医疗教学工作三十余年</p>
              </div>
            </div>
            <div className='starter-right-cards'>
              <div className='subtitle'>
                <img src={feng} alt="FengQiuJu" className="about-subtitle-icon" /> 
                <p>教学主管 - 冯秋菊</p>
              </div>
              <div className='subtitle1'>
                <p>毕业于北京中医药大学</p>
                <p>师承于首届国医大师贺普仁教授</p>
                <p>北京针灸三通法常务理事</p>
                <p>中推（北京）联合研究院学术委员会副主任</p>
              </div>
            </div>
            <div className='starter-right-cards'>
              <div className='subtitle'>
                <img src={yang} alt="YangYiXi" className="about-subtitle-icon" /> 
                <p>教务主管 - 杨忆熙</p>
              </div>
              <div className='subtitle1'>
                <p>本科毕业于北京中医药大学</p>
                <p>中国中医科学院在职研究生 硕士</p>
                <p>曾在中国中医科学院广安门医院急诊科从事临床及教学工作二十年</p>
              </div>
            </div>
            <div className='starter-right-cards'>
              <div className='subtitle'>
                <img src={zhou} alt="ZhouZhongBin" className="about-subtitle-icon" /> 
                <p>总务主管 - 周忠斌</p>
              </div>
              <div className='subtitle1'>
                <p>毕业于东北师范大学 硕士</p>
                <p>马来西亚城市大学 博士</p>
                <p>长春师范大学讲师</p>
                <p>柏辰艺术高中教学校长</p>
                <p>清华大学建筑设计研究院二分院 顾问</p>
              </div>
            </div>
            <div className='starter-right-cards'>
              <div className='subtitle'>
                <img src={hui} alt="HuiJingBo" className="about-subtitle-icon-hui" /> 
                <p>外事兼财务主管 - 惠婧博</p>
              </div>
              <div className='subtitle1'>
                <p>毕业于东北师范大学 硕士</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
};

export default AboutSection;