
import '../../src/App.css'; 
import home from '../assets/home.png'; 
import chevron from '../assets/chevron.png';
import code from '../assets/code.png';
import swipeLeft from '../assets/swipe-left.png';

const HomeSection = () => {
    const handleScrollToAbout = () => {
      document.getElementById('about').scrollIntoView({ behavior: 'smooth' });
    };

return (   
    <div id="home" className="section home-section">
        <div className="home-cards-container">
          <div className="home-card left-card">
            <img src={home} alt="Company" />
          </div>
          <div className="home-card right-card">
            <h1>新焦点中医培训学校</h1>
            <h1>New Axis Training Centre</h1>
            <p>of Traditional Chinese Medical</p>
            <button className="home-button" onClick={handleScrollToAbout}>
              立即开始
              <img src={chevron} alt="Arrow" className="button-icon" />
            </button>
          </div>
        </div>
        <div className="bottom-card-container">
          <div className="bottom-card left-card">
            <img src={code} alt="Company" />
          </div>
          <div className="bottom-card right-card-container">
            <div className='bottom-left-img'>
              <img src={swipeLeft} alt="Arrow" className="button-icon" />
            </div>
            <div className='bottom-right-text'>
              <h3>探索更多精彩内容，立即扫码！</h3>
            </div>
          </div>
        </div>
      </div> 
    );
};

export default HomeSection;
